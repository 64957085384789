import React from 'react'
import one from './1.svg'
import two from './2.svg'
import three from './3.svg'

import five from './5.svg'
import six from './6.svg'
import seven from './7.svg'
import eight from './8.svg'
import nine from './9.svg'
import ten from './10.svg'

import Audi from './auditorium.svg'
export default function Footer() {
    return (

        <div style={{padding:"0rem 2rem",marginTop:'-1rem'}} id="navba1" href="#about-us-section"
        class="scroll-button smoothscroll paddingLobby 

        
        dn-M" >
        
       
        
        
        
        
        
        
        
        
        </div> 
    )
}
